@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Open+Sans&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.subCard {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(41, 40, 40, 0.2);
  transition: 0.3s;
  text-align: center;
  border-radius: 10px;
  padding-bottom: 10px;
  height:500px;
  background-color: #74b3ac;
}
.subCard-text {
  margin-top: 30px;
  margin-bottom: 0px;
  padding-top: 40px;
  color:'#fff';
  font-size:2.2rem !important;
}

.subCard-detail {
  padding: 10px 20px ; 
  text-align: center;
}

.subCard-price {
  padding: 40px 50px 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  color:'#fff';font-size: 3rem;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #337c75;
  font-weight:500;
  font-size:18px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: #337c75;
  padding: 12px 16px;
  text-decoration: none;
  font-size:18px;
  display: block;
  font-weight:500;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f0f0f0;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}



