.AboutPage {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #317C74;
    width: 100%;
    overflow: hidden;
}
.headwrapabout{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.headwrapabout h1 {
    font-size: 43px;
    font-family: Inter,sans-serif;
    font-weight: 800;
    color: #fff;
    text-align: center;
}
.acordin-para p{
    color: #fff;
    font-size: 18px;
    width: 100%;
    margin-bottom: 2px;
    font-weight: 500;
    text-align: justify;
}
.img-wrap img{
    width: 100%;
    height: 350px;
}
.owner1{
    width: 100%;
    height: auto;  
}
.all-image-wrap{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 20px;
}
.break-mobile{
    display: none;
  }
  .em-style-para{
    font-size: 11px;
    position: relative;
    bottom: 0.8rem;
    left: -5px;
    font-style: normal
  }
  .em-style-para-2 {
    font-size: 11px;
    position: relative;
    bottom: 0.8rem;
    left: -8px;
    font-style: normal;
}
@media only screen and (min-device-width:320px) and (max-device-width:664px){
    .break-mobile{
        display: block;
    }
}
@media(min-width:320px) and (max-width:768px){
    .img-wrap{
        width: 100%;
    }
    .em-style-para{
        font-size: 8px;
        position: relative;
        bottom: 0.5rem;
        left: -5px;
        font-style: normal
      }
      .dot::before{
        bottom: 0px;
      }
      .acordin-para-li{
        font-size: 11px !important;
    width: 100% !important;
    margin: 0 auto;
    font-weight: 500;
    text-align: justify;
      }
    
    .acordin-para{
        color: #fff;
        font-size: 12px !important;
        width:100% !important;
        margin:0 auto;
        font-weight: 500;
        text-align: justify;
    }
    .order1 {
        order: 2;
        padding-top: 1rem;
      }
   
      .headwrapabout h1 {
        white-space: pre-line;
      }
      .order2 {
        order: 1;
      }
  
      .mb-left {
        text-align: center!important;
        width: 100% !important;
        font-size: 25px !important;
    }
    .both-btn{
        margin-left: 0px !important;

        
        }
        .list p{
            text-align: center !important;
        }
        .button-wrap{
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            gap: 0em !important;
        }
        .button-about-1 {
            width: 200px;
            height: 60px;
            font-size: 12px !important;
            padding: 10px;
        }
        .para-wrap-about p {
            font-size: 20px;
            line-height: 30px !important;
            color: white;
            text-align: left;
            font-family: Inter,sans-serif;
        }
        .para-wrap-about-2 p {
            font-size: 20px;
            line-height: 30px !important;
            color: white;
            text-align: left;
            font-family: Inter,sans-serif;
        }
        .button-about-1 button{
            font-size: 12px !important;
        }
        .owner1-wrap{
            width: 50%;
        }
        .owner2-wrap{
            width: 50%;
        } 
      
        .owner2 {
            width: 80%;
            height: 100% !important;
        }
        .img-wrap img {
            width: 100%;
            height: 250px;
        }
        .owner-image-wrap{
            gap: 0px !important;
        }
        .both-btn{
            margin-left: 0px;
        }
        .bottommark-4::before{
         transform: translateX(-58%) translateY(-60%) rotate(180deg);
        }
        .bottommark-5::before{
            transform: translateX(-46%) translateY(-117%) rotate(180deg);
        }
        .list .dot{
            font-size: 22px !important;
        }
        .owner3{
            width: 80% !important;
            height: auto;
        }
        .owner3-image-wrap{
            flex-direction: column !important;
        }
        .list-item-corperate{
        
        justify-content: center;
        }
        .list-head{
            text-align: center !important;
        }
        .list .dot{
            text-align: center !important;
        }
        .corphead h3 {
            color: #317C74;
            font-size: 17px !important;
            line-height: 34px !important;
            font-family: Inter,sans-serif;
        }
  .list .dash{
    text-align: center !important;
  }
  .para-wrap-about {
    width: 100% !important;
    padding-top: 25px;
}
 

}
.bottommark-4{
    position: absolute;
    bottom: 0;
    width: 88%;
    height: 50%;
  }
@media(min-width:768px){
    .img-wrap{
        width: 100%;
    }
 


}
/* .img-wrap {
    display: flex;
    width: 100%;
    margin: 5px auto;
} */

.img-wrap {
    display: flex;
    width: 100%;
    margin: 5px auto;
}
.acordin-para{
    color: #fff;
    font-size: 18px;
    width:98%;
    margin:0 auto;
    font-weight: 500;
    text-align: justify;
}
.acordin-para-li {
    color: #fff;
    font-size: 18px;
    width: 98%;
    margin-bottom: 6px;
    /* margin: 0 auto; */
    font-weight: 500;
    text-align: justify;
}
.main-about{
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #317C74;
    display: flex;
    width: 100%;
    height: 100%;
}
.owner-image-wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
}
.para-wrap-about {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.para-wrap-about-2 {
    display: flex;
    flex-direction: column;
    width: 98%;
    padding-top: 1rem;
    height: 100%;
}
.para-wrap-about-2 p {
    font-size: 19px;
    line-height: 39px;
    width: 94%;
    color: white;
    text-align: justify;
    font-family: Inter,sans-serif;
    margin-bottom: 0px;
    
}
@media((min-width:1195px) and (max-width:1366px)){
    .para-wrap-about p {
        font-size: 16px !important;
        line-height: 34px !important;
    }
    .para-wrap-about-2 p{
        font-size: 16px !important;
        line-height: 34px !important;
    }

}
.para-wrap-about p {
    font-size: 19px;
    line-height: 39px;
    width: 100%;
    color: white;
    text-align: justify;
    font-family: Inter,sans-serif;
    margin-bottom: 0px;
}
@media(max-width:768px){
   .owner-image-wrap{
    flex-direction: row;
   }
   .para-wrap-about p{
    width: 100%;
   }  .button-after .accordion-button{
    font-size: 16px  !important;
   
}
 


.style-about {
    font-size: 10px;
    position: relative;
    bottom: 0.8rem;
    left: 1px !important;
    font-style: normal;
}
}
.style-about{
    font-size: 10px;
    position: relative;
    bottom: 0.8rem;
    left: -1px;
    font-style: normal;

}

@media((min-width:756px)and (max-width:1024px)){
    .para-wrap-about p {
        font-size: 13px;
        line-height: 18px;
        text-align: justify;
    }
    .para-wrap-about-2 p {
        font-size: 13px;
        line-height: 18px;
        width: 100% !important;

        text-align: justify;
    }
    .list .dash {
        font-size: 15px !important;
        margin-top: 0px !important;
        margin-bottom: 10.7px !important;
    }
    .list .dot {
        text-align: left;
        font-size: 17px !important;
    }

}
@media only screen and (min-device-width:769px) and (max-device-width:1024px){

    .para-wrap-about p {
        font-size: 13px;
        line-height: 18px;
        text-align: justify;
    }
    .para-wrap-about-2 p {
        font-size: 13px;
        line-height: 18px;
        width: 100% !important;

        text-align: justify;
    }
    .list .dash {
        font-size: 15px !important;
        margin-top: 0px !important;
        margin-bottom: 10.7px !important;
    }
    .list .dot {
        text-align: left;
        font-size: 17px !important;
    }

}
@media((min-width:769px)and (max-width:1024px)){
    .both-btn {
        margin-left: 1.7rem !important;

    }
    .em-style-para-2{
        font-size: 11px;
        position: relative;
        bottom: 0.8rem;
        left: -12px;
        font-style: normal;
    }
    .library-main-para{
        color:#317C74;
        font-size:22px;
        line-height:45px;
        text-align:justify;
      
      
        margin:0 auto;
      }
    .bottommark-5::before {
        transform: translateX(-51%) translateY(-95%) rotate(180deg) !important;
    }
    .button-wrap{
        gap: 2em !important;
    }
}
@media(max-width:768px){
    .para-wrap-about p{
        font-size: 14px;
  
            font-size: 14px;
            width: 100%;
        
    }
    .para-wrap-about-2 p {
        font-size: 14px;
        width: 100%;
    }
}
@media only screen 
  and (min-device-width: 769px) 
  and (max-device-width: 990px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .list .dot {
        text-align: left;
        font-size: 17px !important;
    }
    .list .dash {
        font-size: 15px !important;
        margin-top: 0px !important;
        margin-bottom: 10.7px !important;
    }
    
  }
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .list .dash {
        font-size: 15px !important;
        margin-top: 0px !important;
        margin-bottom: 10.7px !important;
    }
    .list .dash {
        font-size: 15px !important;
        margin-top: 0px !important;
        margin-bottom: 10.7px !important;
    }
    .para-wrap-about p {
        font-size: 15px;
        line-height: 25px;
        text-align: justify;
    }
    .about-head{
        width: 100%;
    }
    .about-head h4{
        width: 100%;
    }
    .para-wrap-about-2 p {
        font-size: 15px;
        line-height: 25px;
        width: 100% !important;
        text-align: justify;
    }
    .list .dot {
        text-align: left;
        font-size: 17px !important;
    }
}

.button-wrap {
    display: flex;
    padding-top: 1.5rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 2em;
    z-index: 9999999;
    position: relative;
}
.button-about-3 {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
    height: 60px;
    font-size: 100%;
}
.button-about-1 {
    width: 220px;
    height: 68px;
    font-size: 12px !important;
    padding: 10px;
}
.both-btn{
margin-left: 5rem;
z-index: 999999;
}
.button-about-1 button {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    border-radius: 50px;
    outline: none;
    background-color: white;
    color: #317C74;
    display: flex;
    font-size: 17px;
}
.button-about-3 button {
    width: 187px;
    height: 49px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    border-radius: 50px;
    outline: none;
    background-color: beige;
    color: #317C74;
    display: flex;
}

.bottommark-4::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 1%;
    display: block;
    height: 200%;
    transform: translateX(-89%) translateY(-97%) rotate(180deg) !important;
    width: 176%;
    background-size: 690px;
    background-image: url(./../../../../src/Assets/AboutImages/watermark.png);
    background-repeat: no-repeat;
}
.para-wrap-about-heading{
    color: #fff;
    font-size: 26px !important;
    text-align: left;
  }

  .para-wrap-about-para{

    line-height: 30px !important;
  }
  @media (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    /* Styles for iPhone 6/7/8 */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
    }
    .dot::before{
        bottom: 0px;
    }
  
  }
  @media (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    /* Styles for iPhone 6/7/8 Plus */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
 }
  }
  @media (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    /* Styles for iPhone X/XS/11 Pro */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
 }
  }
  @media (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    /* Styles for iPhone XR/11 */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;

    }
  }
  @media (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    /* Styles for iPhone XR/11 */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
 }
  }
  @media (device-width: 411px) and (device-height: 731px) and (-webkit-device-pixel-ratio: 2.625) {
    /* Styles for Google Pixel 2 */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg);
 }
  }
  @media (device-width: 411px) and (device-height: 731px) and (-webkit-device-pixel-ratio: 2.625) {
    /* Styles for Google Pixel 2 */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg);
 }
  }
  @media (device-width: 360px) and (device-height: 740px) and (-webkit-device-pixel-ratio: 4) {
    /* Styles for Samsung Galaxy S9/S9+/S8/S8+ */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
 }
  }
  
  @media (device-width: 412px) and (device-height: 915px)  {
    /* Styles for Samsung Galaxy S9/S9+/S8/S8+ */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
 }
  }
  @media (device-width: 393px) and (device-height: 851px) {
    .bottommark-4::before {
        content: "";
        position: absolute;
        top: 0%;
        left: 1%;
        right: 0%;
        width: 122%;
        height: 209%;
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
   background-image: url(./../../../Assets/AboutImages/watermark.png);
        background-repeat: no-repeat;
    }

  }
  @media (device-width: 360px) and (device-height: 640px)  {
    /* Styles for Samsung Galaxy S9/S9+/S8/S8+ */
    .bottommark-4::before {
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
    }
  }
  @media (device-width: 1440px) and (device-height: 566px){
    .bottommark-4::before {
        height: 200%;
        transform: translateX(-89%) translateY(-97%) rotate(180deg) !important;
        width: 176%;
        background-size: 690px;
    }
}
@media (device-width: 1024px) and (device-height: 509px){

    .bottommark-4::before {
        transform: translateX(-85%) translateY(-99%) rotate(180deg) !important;
        height: 140%;
        background-size: 690px;
    }
}
@media (device-width: 320px) and (device-height: 509px){
.bottommark-4::before{
           transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
}
.list-item-corperate{
    text-align: left !important;
    
            }
}
.owner2{
    width: 80%;
    height: auto;
}

@media (device-width: 768px) and (device-height: 509px){

       .owner1-wrap{
            width: 50%;
        }
        .owner2-wrap{
            width: 50%;
        } 

        .owner2 {
            width: 80%;
            height: 100%;
        }
    }
    .close-img{
        --bs-gutter-x: 0.5rem !important;
    }
    /* @media (device-width: 768px) and (device-height: 509px){
        .bottommark-4::before{
               transform: translateX(-58%) translateY(-60%) rotate(180deg);
        }
    }
    @media (device-width: 390px) and (device-height:844px){
        .bottommark-4::before{
           display: none;
        }
    } */
    @media (device-width: 1444px) and (device-height:679px){
        .bottommark-4::before {
            height: 100%;
               transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
           top: 0%;
        }}
        /* @media(device-width:1316px) and (device-height:509px){
            .bottommark-4::before{
               transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
           }
        } */
        @media(device-width:1441px) and (device-height:559px){
            .bottommark-4::before{
                   transform: translateX(-58%) translateY(-60%) rotate(180deg) !important;
            }

        }
        

        .corperate-head{
            padding-top: 3rem;
            padding-bottom: 2rem;
            background: transparent;
        }
        .corp-head-wrap {
            display: flex;
            width: 91%;
            height: 100%;
            flex-direction: column;
        }
        .corphead{
            text-align: left;
            width: 100%;

        }
        .corphead h3{
            color: #317C74;
            font-style: italic;
            font-size: 35px;
            line-height: 45px;
            font-family: Inter,sans-serif;
            margin-bottom: 0px;
            text-align: justify;
        }
        .para-corp{
            width: 100%;
            text-align: left;
        }
        .para-corp p{
              color: #317C74;
              font-size: 16px;
              font-weight: 600;
        }
        .owner3{
    
                width: 100%;
                height: 100%;
                z-index: 999999;
                position: relative;
            

        }
        .list-item-corperate{
            display: flex;
            flex-direction: column;
            height: 100%;

        }
        .list-head{
            text-align: left;
        }
        .list-head h4{
            font-size: 35px;
            color: rgba(255, 255, 255, 0.828);
        }
        
      .list{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
        .dot::before {
        content: ".";
        display: inline-block;
        width: 20px;
        height: 20px;
        color: rgba(255, 255, 255, 0.828);
        font-size: 54px;
        bottom: 9px;
        position: relative;
    }
    .list .dot{
        text-align: left;
        font-size: 30px;
        color: rgba(255, 255, 255, 0.828);
        font-family: Inter,sans-serif;
        margin-bottom: 11px !important;
    }
    .list .withdot{
        text-align: left;
        font-size: 30px;
        text-decoration: none;
        padding-top: 5px;
        margin-left: 20px;
        color: rgba(255, 255, 255, 0.828);
        font-family: Inter,sans-serif;
     
        margin-bottom: 13px !important;
    }
    .dash::before{
        content: "-";
        display: inline-block;
        width: 20px;
        height: 20px;
        color: rgba(255, 255, 255, 0.828);
        font-size: 24px;
    }
    .list .dash{
        font-size: 18px;
        margin-top: 2px;
        margin-bottom: 2px;
        color: rgba(255, 255, 255, 0.828);
        text-align: left;
        
    }
    .btn-contact{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        height: 100%;
    }
    .owner3-image-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 20px;
    }
    .owner3-wrap{
        width: 100% !important;
    }

    .bottommark-5 {
        position: absolute;
        bottom: 0;
        width: 88vw;
        height: 50vw;
    }
    .bottommark-5::before {
        background-image: url(./../../../Assets/AboutImages/watermark.png);
 
            background-repeat: no-repeat;
            content: "";
            background-size: 1306px;
            display: block;
            height: 271%;
            left: 1%;
            position: absolute;
            top: 100%;
            transform: translateX(-53%) translateY(-111%) rotate(180deg);
            width: 142%;
        }
    .list p {
        text-align: left;
        font-size: 30px;
        color: rgba(255, 255, 255, 0.828);
        font-family: Inter,sans-serif;
        margin-bottom: 13px !important;
    }
    .list .ml-14{
        margin-left: 14px;
    }


    @media((min-width:1800px) and (max-width:2600px)){
        .bottommark-2::before{
            background-size: 2788px;
            height: 2000px;
            transform: translateX(100%) translateY(-9%) rotate(0deg);


        }

.text-video-wrap h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-size: 57px;
    color: white;
    text-align: end;
    margin-left: 10px;
}
.para-wrap-about p {
    line-height: 33.5px;
    font-size: 20px;
}
.para-wrap-about-2 p{
    font-size: 20px;
}
        .head-wrap h3 {
            font-size: 40.3px;
        }

        .bottom-mark{
            background-size: 2617px;
        }
        .bottommark-3::before{
            background-size: 1321px;
            -webkit-transform: translateX(-53%) translateY(-58%) rotate(180deg)!important;
            transform: translateX(-36%) translateY(-65%) rotate(180deg)!important;

        }
        .bottommark-5::before {
            transform: translateX(-56%) translateY(-105%) rotate(180deg);
            background-size: 1506px;
        }
        .bottommark-4::before {
            transform: translateX(-90%) translateY(-97%) rotate(180deg) !important;
            background-size: 870px;
        }
    }

  .button-after  .accordion-button::after{
        background-image: url(./../../../../public/assets/img/Services/plus\ \(1\).png)!important;
    }
 .button-after   .accordion-button:not(.collapsed)::after{
        background-image: url(./../../../../public/assets/img/Services/minus-sign.png) !important;
    }
    /* .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 0.25rem;
        font-size: 24px !important;
        color: #317C74 !important;
        font-weight: 900 !important;
        border-top-right-radius: 0.25rem;
    } */
     .button-after  .accordion-button.collapsed{
        border-top-left-radius: 0.25rem;
        font-size: 24px ;
        color: #317C74 !important;
        font-weight: 900 !important;
        border-top-right-radius: 0.25rem;
    }
    .button-after   .accordion-button{
        border-top-left-radius: 0.25rem;
        font-size: 24px ;
        color: #317C74 !important;
        font-weight: 900 !important;
        border-top-right-radius: 0.25rem;
    }
    /* .accordion-body{
        background-color: #317C74;
        color: white;
    } */
    .button-after   .accordion-button:not(.collapsed){
        background-color: #fff !important;
    }
   .button-after   .accordion-button:focus{
        z-index: 3; 
  border-color: #303132 !important; 
    outline: 0; 
  box-shadow: 0 0 0 0.25rem #fffbfb;
    }
    .services-acord{
        width: 80%;
        margin: 0 auto;
    }